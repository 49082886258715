.searchInput {
    position: relative;
}

.resultBox {
    position: absolute;
    background-color: #fff;
    width: 100%;
    min-height: 40px;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgb(216, 216, 216);
    z-index: 500;
    transition: 0.3s;
    /* opacity: 0; */
    /* visibility: hidden; */
}

.searchInput input:focus .resultBox {
    opacity: 1 !important;
    visibility: visible !important;
    margin-top: 5px !important;
}

.resultBox ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.moreData {
    max-height: 200px;
    overflow-y: scroll;
}

.resultBox ul li {
    list-style: none;
    padding: 12px;
    width: 100%;
    line-height: 18px;
    font-size: 16px;
    font-family: inherit;
    color: #444;
    box-shadow: 0px 0px 2px -1px #444;
    transition: 0.3s;
}

.resultBox ul li:hover {
    cursor: pointer;
    background: rgb(245, 245, 245);
}

.moreData::-webkit-scrollbar {
    width: 10px;
}

.moreData::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgb(199, 199, 199);
    border-radius: 10px;
}

.moreData::-webkit-scrollbar-thumb {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    background: #666;
    border-radius: 10px;

}


.moreData::-webkit-scrollbar-thumb:hover {
    background: #444;
}

.NumberInput {
    width: 50px;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 14px;
    line-height: 14px;
    padding: 3px;
    text-align: center;
    box-shadow: 0px 0px 3px -1px rgb(0, 0, 0);
}


.finalFactore {
    width: 100%;
    height: 150px;
    padding: 5px;
    box-shadow: 0px 0px 3px -1px #444;
    font-family: inherit;
    font-size: 15px;
    color: #444;
    line-height: 15px;
}

.finalRight {
    float: right;
    height: 150px;
}

.finalRight span {
    display: block;
    height: 50px;
    padding: 5px;
}

.finalLeft {
    float: left;
    height: 150px;
    width: 200px;
    margin-left: 20px;
}

.finalLeft span {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
}

.finalLeft span:nth-child(2) {
    background-color: #036;
    height: 50px;
    border-radius: 5px;
    color: #fff;
}



.DateInput {
    width: 100%;
}

.DateInput>div>input {
    width: 100% !important;
    margin: 0 !important;
    height: 37px;
    padding-left: 10px;
    text-align: right;
}

/* .DateInput>div>input:focus {
    border: none!important;

} */
.dec{
    width: 100%;
    text-align: center;
    background-color: #14b177;
    color: #fff;
    border-radius: 5px;
    line-height: 30px;
}
.NoData {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px -1px #444;
    font-family: inherit;
    font-size: 17px;
    color: #444;
}


.userInfo {
    width: 100%;
    padding: 10px;
}

.infoBox {
    width: 100%;
    height: 100%;
    /* box-shadow: 0px 4px 8px -3px #333; */
    box-sizing: border-box;
    display: flex;
}

.infoBox ul {
    width: 400px;
}

.userInfo span {
    display: block;
    font-family: inherit;
    font-size: 16px;
    line-height: 30px;
    color: #444;
    direction: inherit;
    padding: 5px 10px;
}